<template>
  <div class="my-2">
    <v-file-input label="Foto" v-model="report.foto"></v-file-input>
    <v-textarea v-model="report.obs" color="teal">
      <template v-slot:label>
        <div>Reportar problema</div>
      </template>
    </v-textarea>
    <v-btn color="primary" dark large @click="enviar()">Enviar</v-btn>
    <br />
    <v-card class="mx-auto" v-show="reports">
      <v-card-text class="py-0">
        <v-timeline dense>
          <v-slide-x-reverse-transition group hide-on-leave>
            <v-timeline-item
              v-for="item in reports.slice().reverse()"
              :key="item.id"
              small
              fill-dot
            >
              <v-alert>
                {{item.id}} - {{item.obs}}
                <br />
                <img :src="'https://loremflickr.com/150/150?lock='+item.id" />
              </v-alert>
            </v-timeline-item>
          </v-slide-x-reverse-transition>
        </v-timeline>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
//import Vuetify from "vuetify/lib";
Vue.filter("reverse", function (value) {
  // slice to make a copy of array, then reverse the copy
  return value.slice().reverse();
});
export default {
  name: "Report",

  data() {
    return {
      report: {},
      id: 1,
      reports: [],
    };
  },
  mounted() {
    if (localStorage.getItem("reports")) {
      this.reports = JSON.parse(localStorage.getItem("reports"));
      this.id = this.reports.length + 1;
    }
  },
  watch: {},
  methods: {
    enviar() {
      this.report.id = this.id;
      this.reports.push(this.report);
      localStorage.setItem("reports", JSON.stringify(this.reports));
      this.id++;
      this.report = {};
    },
  },
};
</script>
